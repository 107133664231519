<template>
  <div>
    <Suspense>
      <router-view />
    </Suspense>
  </div>
</template>
<script>
import "./appkit/assets/appkit/vendor.min.css";
import "./appkit/assets/appkit/appkit.min.css";
import { defineComponent } from 'vue'
export default defineComponent({
  name: "RCBAPP",
})
</script>


<style>
@import "./assets/style/global.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}
#nav a {
    font-weight: bold;
    color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
