import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import axios from "axios";
import {useBank} from "@/composables/simpleStore.ts";

export const CustomerNames = {
    rbi: "RBI",
    ntb: "NTB",
    rcb: "RCB"
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/auth/login'
    },
    {
        path: '/auth/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
    },
    {
        path: '/:bank',
        name: 'main',
        component: () => import('../components/DefaultLayout.vue'),
        children: [
            {
                path: '',
                name: '',
                component: () => import('../views/Home.vue'),
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        name: '',
                        component: () => import('../views/Dashboard.vue'),
                    },
                    {
                        path: 'history/:iban',
                        name: "History",
                        component: () => import('../views/History.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'files',
                        name: 'Files',
                        component: () => import('../views/Files.vue'),
                    }
                ]
            },
            // TODO maybe nest into Dashboard?!
            // {
            //     path: 'history/:iban',
            //     name: "History",
            //     component: () => import('../views/History.vue'),
            //     meta: {
            //         requiresAuth: true
            //     }
            // },
            // {
            //     path: 'files',
            //     name: 'Files',
            //     component: () => import('../views/Files.vue'),
            // },
            {
                path: 'settings',
                name: 'Settings',
                component: () => import('../views/Settings.vue'),
            }
        ]
    }
]
// {
//     path: '/history/:iban',
//     name: "history",
//     component: () => import('../components/DefaultLayout.vue'),
//     children: [
//         {
//             name: 'History', path: '', component: () => import('../views/History.vue')
//         }
//     ],
//     meta: {
//         requiresAuth: true
//     }
// },
// {
//     path: '/files',
//     name: 'files',
//     component: () => import('../components/DefaultLayout.vue'),
//     children: [
//         {
//             name: "Files", path: '', component: () => import("../views/Files.vue")
//         }
//     ]
// }
// ]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, _, next) => {
    // if to route contains any CustomerName, then set the axios base url to the corresponding backend
    const prefix = "/api";
    if (to.params.bank) {
        const bank = to.params.bank;
        if (bank === CustomerNames.rbi.toLowerCase()) {
            axios.defaults.baseURL = `/${CustomerNames.rbi.toLowerCase()}${prefix}`;
        } else if (bank === CustomerNames.ntb.toLowerCase()) {
            axios.defaults.baseURL = `/${CustomerNames.ntb.toLowerCase()}${prefix}`;
        } else if (bank === CustomerNames.rcb.toLowerCase()) {
            axios.defaults.baseURL = `/${CustomerNames.rcb.toLowerCase()}${prefix}`;
        }

        useBank.value = to.params.bank.toString();
        // axios.defaults.headers.common['bank'] = to.params.bank.toString();
    } else {
        axios.defaults.baseURL = prefix;
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // TODO no jwt in local storage, instead axios interceptors
        if (!localStorage.getItem("jwt")) {
            return next({
                name: 'Login'
            })
        }
    }
    return next();
})

export interface Routing {
    goto: (whereTo: string) => Promise<void>
}

/**
 * Routing implementation for vue-router for third party libs
 * For vue itself you can use this.$router or useRouter
 *
 * This is a generalization for other services or external implementations
 * that has no idea which routing framework that is used
 */
export class VueRouting implements Routing {
    async goto(whereTo: string) {
        await router.push({name: whereTo})
    }
}

export default router
