import { createApp } from 'vue'
import App from './App.vue'
import router, {Routing, VueRouting} from './router/index.ts'
import axios, {AxiosError} from "axios";
import 'sweetalert2/dist/sweetalert2.min.css'

const routing : Routing = new VueRouting();

axios.interceptors.response.use(
    response => response,
    /**
     * Transform the errors in something more general.
     * Basic errors like 401, 403, 404, 500 are transformed in errors for developers
     *
     * 400 is just thrown to the frontend, as it sometime contains more
     * complex error messages
     *
     * @param error
     */
    (error : AxiosError) => {
        if (error.response === undefined) throw Error("Http error but undefined?! Something is wrong...")

        if (error.response.status === 401) {
            Promise.resolve()
                .then(async () => await routing.goto("Login"));
            throw Error("Not authorized. Please login.")
        }

        if (error.response.status === 404) {
            throw Error("Endpoint not found - 404. Is the server running?");
        }

        if (error.response.status === 500) {
            throw Error("Server error - 500. Did the server crash?")
        }

        return Promise.reject(error);
    });

createApp(App)
    .use(router)
    .mount('#app')
