import {useStorage} from "@vueuse/core";
import {ref} from "vue";

export const useBank = useStorage('bank', 'rbi')

export const useAuth = () => {
    const {value: bank} = useBank;

    const isAuthenticated = () => {
        return bank !== null;
    }

    const freshLogin = ref(false);

    return {
        isAuthenticated,
        freshLogin
    }
}
